import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../../components/seo'
import Title from '../../components/title'
import Header from '../../components/header'
import Container from '../../components/container'
import FeatureBlock from '../../components/feature-block'
import Layout from '../../layout'
import Image from '../../components/image'
import Brands from '../../components/brands'
import AnimatedArticle from '../../components/animated-article'
import AnimatedAside from '../../components/animated-aside'
import P from '../../components/paragraph'
import BrandBadge from '../../components/brand-badge'

const BoadiceaPage = () => {
  const images = useStaticQuery(graphql`
    query {
      bottle: file(relativePath: { eq: "brands/boadicea/boadicea-bottle.png" }){
        childImageSharp {
          fluid(maxWidth: 770) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      legend: file(relativePath: { eq: "brands/boadicea/legend.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      colour: file(relativePath: { eq: "brands/boadicea/colour.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      group: file(relativePath: { eq: "brands/boadicea/boadicea-group.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title='Boadicea the Victorious'
        description={`
          Boadicea the Victorious is an independent British fragrance brand.
          Taking a defining moment in history as our inspiration, they have
          created a range of gender neutral perfumes that are quintessentially
          British and of incomparable quality
        `}
      />

      <Header />

      <Container wide noPadding>
        <FeatureBlock>
          <AnimatedArticle onLoad badge>
            <Title fancy>Boadicea the Victorious</Title>
            <P>
              Boadicea the Victorious is an independent British fragrance brand.
              Taking a defining moment in history as our inspiration, we have
              created a range of gender neutral perfumes that are
              quintessentially British and of incomparable quality. Each one of
              the iconic bottles has been hand crafted by some of Britain&apos;s
              finest artisans, and illuminates how the brand champions British
              heritage and values. A fusion of tradition, heritage and timeless
              classic luxury, Boadicea the Victorious is the ultimate
              celebration of beauty, strength and nobility.
            </P>
            <BrandBadge brand='boadicea' />
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              css={`
                @media(min-width: ${({ theme }) => theme.breakpoint.large}) {
                  margin-top: 200px;
                }
              `}
              fluid={images.bottle.childImageSharp.fluid}
              notch='bottomLeftSmall'
              alt='Boadicea Salon de Parfums, Harrods'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container
        wide
        noPadding='top'
        overflow
      >
        <FeatureBlock center grey={false}>
          <AnimatedAside right>
            <Image
              fluid={images.group.childImageSharp.fluid}
              alt='Boadicea Perfume Bottle'
              notch='topRight'
            />
          </AnimatedAside>
          <AnimatedArticle>
            <P>
              A warrior queen, golden torcs around her neck, leads her Iceni
              army into battle, the epitome of courage and determination. Iconic
              and unflinching, she raises her face to the prevailing breezes,
              catching the unmistakable scent of victory. Her passion is
              irrepressible, her lust for justice all pervading, her drive to
              succeed unmatched. These are qualities captured in the perfumes of
              Boadicea the Victorious, non-gender specific fragrances that are a
              celebration of timeless British artisanship, creativity and
              originality.
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container>

      <Container width={1200}>
        <Title fancy>Other Brands</Title>
        <Brands />
      </Container>
    </Layout>
  )
}

export default BoadiceaPage
